<template>
    <setting-layout :title="$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.title')">
        <v-container>
            <v-form>
                <v-row v-for="(item, i) in fields" :key="i">
                    <v-col>
                        <div class="pb-1" :class="{'error--text': errors[item.field_name]}">{{ item.label }}</div>
                        <text-field v-if='item.type==="text"'
                                    :placeholder="item.placeholder"
                                    :error-messages="errors[item.field_name]"
                                    v-model="model[item.field_name]"/>
                        <v-select
                            v-else
                            background-color="rgba(237,237,237, .1)"
                            color="orange"
                            :items="item.items"

                            :placeholder="item.placeholder"
                            solo
                            flat
                            item-color="orange"
                            item-text="text"
                            item-value="val"
                            v-model="model[item.field_name]"
                            hide-details


                        ></v-select>
                    </v-col>
                </v-row>
                <p class="text-body-2 mt-3">
                    <span v-html="convertCurrencyMessage"></span>
                </p>
                <p class="text-body-2 mt-3">
                    {{$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.note_1')}}
                </p>
                <p class="text-body-2">
                    {{$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.note_2')}}
                </p>
                <bottom-control-block :checked="defaultBankTransfer"
                                      days-receive="1-3"
                                      max-days-receive="10"
                                      min-payment="100"
                                      :processing-fee="1"
                                      @change="changeDefault"
                                      :loading="$store.getters.loadingBtn(['setting', 'withdraw', 'directBtn'])"
                                      @done="save">
                </bottom-control-block>

            </v-form>

        </v-container>

        <WithdrawalFormErrorDialog
            v-if="this.isShowWithdrawFormErrorDialog"
            @close="handleCloseWithdrawErrorFormDialog"
        >
            <template #title>
                {{ $t('dialogs.withdrawal_form_error.bank_transfer.title') }}
            </template>

            <template #description>
                {{ $t('dialogs.withdrawal_form_error.bank_transfer.description') }}
            </template>
        </WithdrawalFormErrorDialog>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import countriesSelect from '@/configs/countriesSelect'
import BottomControlBlock from "@/components/creator/BottomControlBlock";
import { mapActions, mapMutations } from "vuex";
import AccessCameraDialog from "@/components/app/dialogs/AccessCameraDialog.vue";
import WithdrawalFormErrorDialog from "@/components/app/dialogs/WithdrawalFormError.vue";

export default {
    name: "BankTransfer",
    data() {
        return {
            success: {},
            errors: {},
            model: {},
            defaultBankTransfer: this.$store.state.user.user.data.withdrawDefault === 'bank',
            isShowWithdrawFormErrorDialog: false,
        }
    },
    computed: {
        fields() {
            return [
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_name'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_name'), field_name: 'beneficiary_name'},
                {type: 'select', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_country'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_country'), items: countriesSelect, field_name: 'beneficiary_country'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_street'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_street'), field_name: 'beneficiary_street'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_city'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_city'), field_name: 'beneficiary_city'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_state'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_state'), field_name: 'beneficiary_state'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_beneficiary_zip'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.beneficiary_zip'), field_name: 'beneficiary_zip'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_iban_number'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.iban_number'), field_name: 'iban_number'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_name'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_name'), field_name: 'bank_name'},
                {type: 'select', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_bank_country'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.bank_country'), items: countriesSelect, field_name: 'bank_country'},
                {type: 'text', placeholder: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.placeholder_swift_code'), label: this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.swift_code'), field_name: 'swift_code'},
            ]
        },
        convertCurrencyMessage() {
            const rateLink = `<a href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/eurofxref-graph-usd.en.html" target="_blank"><u>ECB rate</u></a>`;
            return this.$t('pages.user.setting.creator.withdraw_options.withdraw.bank_transfer.convert_currency', { ecb_rate_link: rateLink });
        }
    },
    methods: {
        changeDefault() {
            this.defaultBankTransfer = !this.defaultBankTransfer
            this.$store.state.user.user.data.withdrawDefault = this.defaultBankTransfer ? 'bank' : null
        },
        validateForm(form) {
            for (const key in form) {
                if (form.hasOwnProperty(key)) {
                    const value = form[key]
                    if (value === null || value === undefined || value === '') {
                        return false
                    }
                }
            }
            return true
        },
        save() {
            const isValid = this.validateForm(this.model);
            if (!isValid) {
                this.isShowWithdrawFormErrorDialog = true;
                return
            }

            this.model.default = this.defaultBankTransfer
            this.sendBankTransfer({model: this.model})
                .then((result) => {
                    this.success = result || {}
                    this.errors = {}
                    this.showSnackBar(this.$t("dialogs.payment_method.saved"));
                }, (error) => {
                    this.errors = error.response.data.errors || {}
                    this.showSnackBar(this.$t("dialogs.payment_method.not_saved"));
                })
        },
        handleCloseWithdrawErrorFormDialog() {
            this.isShowWithdrawFormErrorDialog = false
        },
        ...mapMutations(["showSnackBar"]),
        ...mapActions([
            'sendBankTransfer',
            'getBankTransfer',
        ]),
    },
    mounted() {
        this.getBankTransfer().then((response) => {
            this.model = response.data || {}
        })
    },
    components: {
        WithdrawalFormErrorDialog,
        AccessCameraDialog,
        BottomControlBlock,
        TextField,
        SettingLayout
    }
}
</script>

<style scoped>

</style>
